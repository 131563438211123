import logo from './logo.svg';
import './App.css';
import QrReader from 'react-qr-reader'
import {useState} from 'react'

function App() {
	
	const BLOCK_SIZE = "50px"
	const BORDER_SIZE = "2px"
	
	const [result, setResult] = useState("No result")
	
	const handleScan = (data) => {
		if (data) {
			setResult(data)
		}
	}
	const handleError = (err) => {
		console.error(err)
	}
	
  return (
    <div className="App">
		<div style={{width : "100vw", height : "100vh", backgroundColor : "white"}}>
			<div style={{ width: '75vw', height : "75vw", marginLeft : "12.5%", backgroundColor : "#FAFAFA", marginTop : "110px", position : "relative" }}>
				<div style={{zIndex : 0, backgroundColor : "purple", width : BLOCK_SIZE, height : BLOCK_SIZE, position : "absolute", top : "calc(0px - " + BORDER_SIZE + ")", left : "calc(0px - " + BORDER_SIZE + ")"}}></div>
				<div style={{zIndex : 0, backgroundColor : "purple", width : BLOCK_SIZE, height : BLOCK_SIZE, position : "absolute", top : "calc(0px - " + BORDER_SIZE + ")", right : "calc(0px - " + BORDER_SIZE + ")"}}></div>
				<div style={{zIndex : 0, backgroundColor : "purple", width : BLOCK_SIZE, height : BLOCK_SIZE, position : "absolute", bottom : "calc(0px - " + BORDER_SIZE + ")", left : "calc(0px - " + BORDER_SIZE + ")"}}></div>
				<div style={{zIndex : 0, backgroundColor : "purple", width : BLOCK_SIZE, height : BLOCK_SIZE, position : "absolute", bottom : "calc(0px - " + BORDER_SIZE + ")", right : "calc(0px - " + BORDER_SIZE + ")"}}></div>
				<QrReader
					delay={300}
					onError={handleError}
					onScan={handleScan}
					style={{ width: '100%', position : "absolute", top : "0px", left : "0px"}}
					showViewFinder={false}
					facingMode={"environment"}
				/>
			</div>
			<p>{result}</p>
		</div>
    </div>
  );
}

export default App;
